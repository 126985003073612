import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import AppContextProvider from "../context/app-context";
import ScrollToTop from "./global/scroll-to-top";

import Header from "./header";
import Main from "./main";
import AboutUs from "./about_us";
import ContactUs from "./contact_us";
import Footer from "./footer";

const App = () => {

    return (
        <AppContextProvider>
            <BrowserRouter>
                <ScrollToTop>
                    <Header />
                    <Routes>
                        <Route exact path='/*' element={<Main />} />
                        <Route exact path='/about-us' element={<AboutUs />} />
                        <Route exact path='/contact-us' element={<ContactUs />} />
                    </Routes>
                    <Toaster position="bottom-left" reverseOrder={true} />
                    <Footer />
                </ScrollToTop>
            </BrowserRouter>
        </AppContextProvider>
    );
};

export default App;