const standardResponse = {
    error: false,       // required boolean
    message: null,      // only applies to errors
    data: null       // raw data - result param
};

const useUIResponse = () => {

    const uiResponse = (result, options) => {
        const res = { ...standardResponse };
        if (options && options.error) {
            res.error = true;
            res.message = options?.errorMessage || options.exception?.message;
            if (options?.exception) {
                console.error(options?.exception?.stack);
            }
        }
        res.data = result;
        return res;
    };

    return {
        uiResponse
    };
    
};

export default useUIResponse;