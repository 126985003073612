import React from "react";
import { Link } from "react-router-dom";

import "../assets/css/main_intro.scss";

const MainIntro = () => {
    return (
        <>
            <div className="container main-intro-container">
                <h2 className="page-title">Welcome</h2>
                <div className="page-title-divider">
                    <hr />
                </div>
                <p className="page-lead">
                    We're a family owned and operated business that prides itself in the creation of healthy, tasty, fun popcorn snacks. Our facilities are meticulously maintained, inspected and certified to be clean and safe. Our flavorful gluten-free creations are sure to satisify your crunchy cravings!
                </p>
            </div>
        </>
    );
};

export default MainIntro;