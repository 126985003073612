// Send message via EveryDVM API > SendGrid
import apiURI from '../enum/api-uri';
import method from '../enum/method';
import useAPI from '../hooks/use-api';
import uiResponse from '../hooks/use-uiResponse';

const EVERYDVM_TOKEN = 'everydvm_token';

const useMessageService = () => {
    const api = useAPI();
    const myToken = localStorage.getItem(EVERYDVM_TOKEN);

    const sendMessage = async messageData => {
        try {
            const res = await api.request({
                method: method.POST,
                uri: apiURI.AUTH,
                route: '/v1/messages',
                data: messageData,
                token: myToken
            });

            return res;

        } catch (e) {
            return uiResponse (false, {
                error: true,
                exception: e,
                errorMessage: e.message
            });
        }
    };

    return {
        sendMessage
    };
};

export default useMessageService;